
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

@import "node_modules/reset-css/_reset.scss";

// Variables
@import "variables";

@import "mixins";

// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.btn-primary {
  background-color: $jam-color-brown;
  border: none; // @todo: style better
}

.form-control:focus {
  border-color: $jam-color-green;
}



#header {
  border-bottom: 1px solid #EEEEEE;
  padding: 14px 0;
}

.navbar {
  margin-bottom: 0;
}

.navbar-toggle {
  background-color: $jam-color-brown;
  color: $jam-color-beige;
}

.logo {
  //background-image: url('/assets/images/favicon-96x96.png');
  //width: 96px;
  //height: 96px;
  //background-size: cover;
  //background-position: center center;
  //background-repeat: no-repeat;
  position: absolute;
  z-index: 10;
  padding: 4px 10px;
}

body,
button,
input,
select,
textarea {
  color: #666666;
  font: 16px 'Lato', sans-serif;
  line-height: 20px;
  word-wrap: break-word;
}

body {
  background: #eaeaea;
}

.displayed {
  display: block;
}

.ng-cloak {
  visibility: hidden !important;
}

#page {
  margin: 20px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px rgba(100, 100, 100, 0.3);
}

.search-button {
  background-color: darken($jam-color-green, 20);
  color: $jam-color-beige;
}

.search-button:hover {
  background-color: darken($jam-color-green, 40);
  color: $jam-color-beige;
}

.bg-green {
  background-color: $jam-color-green;
}

.bg-dark-green {
  background-color: darken($jam-color-green, 30);
}

.bg-brown {
  background-color: $jam-color-brown;
}

.menu-link {
  color: $jam-color-brown;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px;
}

.menu-link:hover {
  color: lighten($jam-color-brown, 10%);
  text-decoration: none;
}

.padded {
  padding: 10px;
}

.full-width {
  width: 100%;
}

.v-padded {
  @include v-padded(10px);
}

.v-padded-sm {
  @include v-padded(12px);
}

.v-padded-md {
  @include v-padded(24px);
}

.v-padded-lg {
  @include v-padded(48px);
}

.v-padded-lg-top {
  @include v-padded(48px);
  padding-bottom: 0;
}

.margin-vert {
  @include v-margined(10px);
}

.margin-vert-sm {
  @include v-margined(12px);
}

.margin-vert-md {
  @include v-margined(24px);
}

.margin-vert-lg {
  @include v-margined(48px);
}

.text-justified {
  text-align: justify;
}

a {
  color: $jam-color-brown;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: lighten($jam-color-brown, 30%);
}

p {
  line-height: 30px;
  text-align: justify;
  text-justify: inter-word;
}

.h1 {
  font-size: 24px;
}

.paragraph-before {
  margin-bottom: 20px;
}

.text-s {
  font-size: $font-size-s;
}

.text-xs {
  font-size: $font-size-xs;
}

.text-xl {
  font-size: $font-size-xl;
}

.text-xxl {
  font-size: $font-size-xl;
}

.text-beige {
  color: $jam-color-beige;
}

.text-white {
  color: #FFFFFF;
}

#title-bar {
  //min-height: 80px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.title-icon {
  margin-right: 14px;
}

.link-camouflaged, .link-camouflaged:hover, .link-camouflaged:active {
  text-decoration: none;
  color: inherit;
}

.division-title {
  text-transform: uppercase;
  color: $jam-color-brown;
  margin-bottom: 10px;
}

.division-title > a {
  @extend .link-camouflaged;
  padding: 10px 0;
}

.employee-container {
  margin-bottom: 80px;
}

.employee-details-container {
  min-height: 80px;
}

.employee-thumbnail {
  width: 150px;
  height: 150px;
  border-color: $jam-color-green;
}

.maps-container {
  /*position: relative;*/
  height: 300px;
  /*overflow: hidden;*/
}

.maps-container iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

.division-detail-container {
  min-height: 180px;
  margin-bottom: 40px;
}