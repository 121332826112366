
// Body
$body-bg: #f5f8fa;

// Borders
//$laravel-border-color: darken($body-bg, 10%);
//$list-group-border: $laravel-border-color;
//$navbar-default-border: $laravel-border-color;
//$panel-default-border: $laravel-border-color;
//$panel-inner-border: $laravel-border-color;

$jam-color-brown: #493E2C;
$jam-color-green: #AFD8C3;
$jam-color-beige: #F9F6E1;

$font-size-xs: 12px;
$font-size-s: 13px;
$font-size-l: 16px;
$font-size-xl: 22px;
$font-size-xxl: 36px;